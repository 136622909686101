import React from 'react';

import { pageLinks } from '../data/pageLinks';

import ContentBlock from '../components/v2/ContentBlock';
import Hero from '../components/v2/Hero';
import Layout from '../components/v2/Layout';
import PageSection from '../components/v2/PageSection';
import Tabs from '../components/v2/ui-blocks/Tabs';
import Image from '../components/v2/Image';
import ImageGrid from '../components/v2/ImageGrid';
import ImageScroller from '../components/v2/ui-blocks/ImageScroller';
import PrevNextNavFooter from '../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter';

import hero_img from '../images/10. Partnerships/partnerships-header-filter40-v2-2560x1728.jpg';

import Toyota_Main_Logo_H_svg from '../images/3. Logo Page/Toyota_Main_Logo_H.svg';
import Toyota_Main_Logo_V_svg from '../images/3. Logo Page/Toyota_Main_Logo_V.svg';

import Partnership_Logo_svg from '../images/10. Partnerships/Partnership_Logo.svg';

import lgp_stacked_partnerships_svg from '../images/10. Partnerships/LGP Stacked – Primary – 800x400.svg'

import Partnership_Lockup_V_svg from '../images/10. Partnerships/Partner Lockup – Side-by-side 1 – 800x400.svg';
import Partnership_Lockup_H_svg from '../images/10. Partnerships/Partner Lockup – Side-by-side 2 – 800x400.svg';
import Partnership_Lockup_V_Stacked_svg from '../images/10. Partnerships/Partner Lockup – Stacked 1 – 400x800.svg';
import Partnership_Lockup_H_Stacked_svg from '../images/10. Partnerships/Partner Lockup – Stacked 2 – 800x800.svg';

import Partnership_Lockup_Config_V_svg from '../images/10. Partnerships/Partner Lockup – Clear Space 1.svg';
import Partnership_Lockup_Config_H_Stacked_svg from '../images/10. Partnerships/Partner Lockup – Clear Space 2.svg';
import Partnership_Lockup_Config_H_svg from '../images/10. Partnerships/Partner Lockup – Divider Line.svg';

import Partnership_Lockup_Divid_Line_Full_Colour_svg from '../images/10. Partnerships/Partner Lockup – Divider Colour – Full Colour – 400x800.svg';
import Partnership_Lockup_Divid_Line_Single_Colour_svg from '../images/10. Partnerships/Partner Lockup – Divider Colour – Single Colour – 400x800.svg';
import Partnership_Lockup_Divid_Line_Full_colour_reversed_svg from '../images/10. Partnerships/Partner Lockup – Divider Colour – Full Colour Reversed – 400x800.svg';

import partnerships_layout_example_1 from '../images/10. Partnerships/Partnerships Layout Example 1 – 1280px.jpg';
import partnerships_layout_example_2 from '../images/10. Partnerships/Partnerships Layout Example 2 – 1280px.png';
import partnerships_layout_example_3 from '../images/10. Partnerships/Partnerships Layout Example 3 – 1280px.jpg';
import partnerships_layout_example_4 from '../images/10. Partnerships/Partnerships Layout Example 4 – 1280px.jpg';

const introText =
  'There are various ways to create co-branding partnerships and sponsorship lockups. To ensure the Toyota brand has visual impact and consistency, please refer to the following guidelines.';

const Partnerships = ({ location }) => {
  return (
    <Layout appLocation={location}>
      <Hero
        image={hero_img}
        text={introText}
        navindex="0"
        textPos={'flex-start'}
        marginTop={'10%'}
      />
      <PageSection sectionId="logo-selection">
        <ContentBlock>
          <h2>Logo Selection</h2>
          <div className="text">
            <p>
              Always aim to use the Toyota ‘<em>Let’s Go Places</em>’ logo on partnership collateral. This will help maximise visual impact when creating partnerships with other co-brands. When necessary single-colour logo options are available.
            </p>
            <p>In special cases a vehicle logo may be used.</p>
            <p>
              Never use <em>Let’s Go Places</em> logo in co-branding lockups.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid vertPadding={true}>
          <Image width="100%" src={lgp_stacked_partnerships_svg}></Image>
        </ImageGrid>
      </PageSection>
      <PageSection sectionId="title-sponsorships">
        <ContentBlock>
          <h2>Title Sponsorships</h2>
          <div className="text">
            <p>
              For title sponsorships, the Toyota logo may be closely aligned or
              integrated with the logo or title of the event being sponsored.
            </p>
            <p>The full colour horizontal Toyota logo is preferred.</p>
            <p>
              Minimum clear space of 1X around the Toyota logo is needed to
              ensure visual impact.
            </p>
            <p>
              The X unit of measurement is derived from the 3x3 grid of the
              staging platform.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid vertPadding={true}>
          <Image width="100%" src={Partnership_Logo_svg}></Image>
        </ImageGrid>
      </PageSection>
      <PageSection sectionId="co-branding-lockups">
        <ContentBlock>
          <h2>Co-Branding Lockups</h2>
          <div className="text">
            <p>
              The full-colour Toyota logo (horizontal or vertical) is preferred.
              Select the version that best matches the shape of the co-branding
              logo and the surrounding layout.
            </p>
            <p>
              The Toyota logo should carry the same visual weight as the
              co-branding logo, unless otherwise specified.
            </p>
            <p>
              The Toyota logo should be first in the lockup unless otherwise
              specified.
            </p>
            <p>
              Toyota would prefer to create co-branding lockups.
            </p>
            <p>
              Never create lockups using the Toyota logo with store branding.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="logo"
          backgroundColor="white"
          items={[
            {
              content: {
                style: 'image',
                image: {
                  svg: Partnership_Lockup_V_svg,
                  style: {
                    // padding: '5rem',
                    maxHeight: '50rem',
                    maxWidth: '90vw',
                    margin: 'auto'
                  }
                }
              },
              label: 'Side-By-Side 1'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Partnership_Lockup_H_svg,
                  style: {
                    // padding: '5rem',
                    maxHeight: '50rem',
                    maxWidth: '90vw',
                    margin: 'auto'
                  }
                }
              },
              label: 'Side-By-Side 2'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Partnership_Lockup_V_Stacked_svg,
                  style: {
                    // padding: '5rem',
                    maxHeight: '50rem',
                    maxWidth: '90vw',
                    margin: 'auto'
                  }
                }
              },
              label: 'Stacked 1'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Partnership_Lockup_H_Stacked_svg,
                  style: {
                    // padding: '5rem',
                    maxHeight: '50rem',
                    maxWidth: '90vw',
                    margin: 'auto'
                  }
                }
              },
              label: 'Stacked 2'
            }
          ]}
        />
      </PageSection>
      <PageSection sectionId="configurations">
        <ContentBlock>
          <h2>Configurations</h2>
          <div className="text">
            <h3>Clear space</h3>
            <p>
              When combining the Toyota logo with another logo always use a
              divider line. The space between the logos and divider line should
              be 1X.
            </p>
            <p>
              It’s important to use the 1X minimum clear space requirement
              around the entire lockup.
            </p>
            <p>
              The X unit of measurement is derived from the 3x3 grid of the
              staging platform.
            </p>
          </div>
        </ContentBlock>

        <ImageGrid vertPadding={true}>
          <Image width="50%" src={Partnership_Lockup_Config_V_svg} />
          <Image width="50%" src={Partnership_Lockup_Config_H_Stacked_svg} />
        </ImageGrid>

        <ContentBlock>
          <div className="text">
            <h3>Divider Line</h3>
            <p>
              The divider line should extend to the full height or width of the
              Toyota logo including the wordmark. The thickness of the divider
              line should never be less than half the width of the downstroke of
              the T in the Toyota wordmark.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid vertPadding={true}>
          <Image width="100%" src={Partnership_Lockup_Config_H_svg} />
        </ImageGrid>
        <ContentBlock>
          <div className="text">
            <h3>Divider Colour</h3>
            <p>
              The divider line should always be the same colour as the Toyota
              wordmark as shown.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="logo"
          backgroundColor="white"
          items={[
            {
              content: {
                style: 'image',
                image: {
                  svg: Partnership_Lockup_Divid_Line_Full_Colour_svg,
                  style: {
                    padding: '5rem',
                    maxHeight: '50rem',
                    maxWidth: '90vw',
                    margin: 'auto'
                  }
                }
              },
              label: 'Full Colour'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Partnership_Lockup_Divid_Line_Single_Colour_svg,
                  style: {
                    padding: '5rem',
                    maxHeight: '50rem',
                    maxWidth: '90vw',
                    margin: 'auto'
                  }
                }
              },
              label: 'Single Colour'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: Partnership_Lockup_Divid_Line_Full_colour_reversed_svg,
                  style: {
                    padding: '5rem',
                    maxHeight: '50rem',
                    maxWidth: '90vw',
                    margin: 'auto'
                  }
                }
              },
              label: 'Full Colour Reversed'
            }
          ]}
        />
      </PageSection>
      <PageSection sectionId="layout-examples">
        <ContentBlock>
          <h2>Layout Examples</h2>
        </ContentBlock>
        <ImageScroller
          vertPadding={true}
          style={{ backgroundColor: 'white' }}
          images={[
            {
              image: {
                svg: partnerships_layout_example_1,
                style: { padding: '5rem', maxHeight: '70rem', maxWidth: '90vw' }
              }
            },
            {
              image: {
                svg: partnerships_layout_example_2,
                style: { padding: '5rem', maxHeight: '70rem', maxWidth: '90vw' }
              }
            },
            {
              image: {
                svg: partnerships_layout_example_3,
                style: { padding: '5rem', maxHeight: '70rem', maxWidth: '90vw' }
              }
            },
            {
              image: {
                svg: partnerships_layout_example_4,
                style: { padding: '5rem', maxHeight: '70rem', maxWidth: '90vw' }
              }
            }
          ]}></ImageScroller>
      </PageSection>
      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Product Campaigns"
        previousUrl={pageLinks.campaigns}
      />
    </Layout>
  );
};

export default Partnerships;
